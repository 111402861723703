import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Spoiler Alert: Targeting Tai Lopez's Audience Is Working`}</h1>
    <p><strong parentName="p">{`Day 2 of the slack experiment!`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "896px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "117.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAYABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAH2es0UGaAH/8QAGRAAAQUAAAAAAAAAAAAAAAAAAQIQICFB/9oACAEBAAEFAkUG2P8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwIf/8QAGRABAAMBAQAAAAAAAAAAAAAAAQAQESFB/9oACAEBAAE/IRiJkEr1e9m1/9oADAMBAAIAAwAAABBgADz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAfEAACAgEEAwAAAAAAAAAAAAABEQAxECFBUWGBkaH/2gAIAQEAAT8QM+gFRC77wgUAuB7j7gAD08uJyPcbqf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tai Lopez Slack 1",
          "title": "Tai Lopez Slack 1",
          "src": "/static/4e4db90a12db28eb33aa38e80620a1c4/fd39b/tai-lopez-slack-convo-1.jpg",
          "srcSet": ["/static/4e4db90a12db28eb33aa38e80620a1c4/a6407/tai-lopez-slack-convo-1.jpg 400w", "/static/4e4db90a12db28eb33aa38e80620a1c4/a24e6/tai-lopez-slack-convo-1.jpg 800w", "/static/4e4db90a12db28eb33aa38e80620a1c4/fd39b/tai-lopez-slack-convo-1.jpg 896w"],
          "sizes": "(max-width: 896px) 100vw, 896px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "954px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "81.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdqk6jEH/8QAFxAAAwEAAAAAAAAAAAAAAAAAAQIQIf/aAAgBAQABBQJaMv8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFRABAQAAAAAAAAAAAAAAAAAAECH/2gAIAQEABj8CjX//xAAaEAACAwEBAAAAAAAAAAAAAAABEQAQIUGB/9oACAEBAAE/IUA6EBrJN7Mr/9oADAMBAAIAAwAAABAgz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAQEAAgEFAAAAAAAAAAAAAAERADEQIUFhgZH/2gAIAQEAAT8QjjZ9Gy54J74KCN77OCCCfcEdNz//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tai Lopez Slack 2",
          "title": "Tai Lopez Slack 2",
          "src": "/static/f3f36c5fbb98a651034efb088a5a56e7/6ebfe/tai-lopez-slack-convo-2.jpg",
          "srcSet": ["/static/f3f36c5fbb98a651034efb088a5a56e7/a6407/tai-lopez-slack-convo-2.jpg 400w", "/static/f3f36c5fbb98a651034efb088a5a56e7/a24e6/tai-lopez-slack-convo-2.jpg 800w", "/static/f3f36c5fbb98a651034efb088a5a56e7/6ebfe/tai-lopez-slack-convo-2.jpg 954w"],
          "sizes": "(max-width: 954px) 100vw, 954px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1316px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABvaCPDEFf/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAIBAxARITH/2gAIAQEAAQUCZajvHBdGbf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQADAAAAAAAAAAAAAAAAABARYXH/2gAIAQEABj8CrWH/xAAaEAEAAgMBAAAAAAAAAAAAAAABESEAEFFB/9oACAEBAAE/IZ4VNhPGVJw1Scbf/9oADAMBAAIAAwAAABAMD//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EFn/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxAJ/8QAHBABAQEAAQUAAAAAAAAAAAAAAREAECExUYGh/9oACAEBAAE/EEbriD3BGevuKnaA3hVCvQzT4w0s3//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tai Lopez Slack 3",
          "title": "Tai Lopez Slack 3",
          "src": "/static/1ea561ddc168231b211c158c66c89466/d28f3/tai-lopez-slack-convo-3.jpg",
          "srcSet": ["/static/1ea561ddc168231b211c158c66c89466/a6407/tai-lopez-slack-convo-3.jpg 400w", "/static/1ea561ddc168231b211c158c66c89466/a24e6/tai-lopez-slack-convo-3.jpg 800w", "/static/1ea561ddc168231b211c158c66c89466/d28f3/tai-lopez-slack-convo-3.jpg 1316w"],
          "sizes": "(max-width: 1316px) 100vw, 1316px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1096px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe3cWaD/xAAVEAEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAQABBQIpf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAACBR/9oACAEBAAY/Aov/xAAaEAADAAMBAAAAAAAAAAAAAAAAAREQITFx/9oACAEBAAE/IZnPAmsaoqKf/9oADAMBAAIAAwAAABBzz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAAIDAQEAAAAAAAAAAAAAAAABETGhIbH/2gAIAQEAAT8Q4IdcEC8LFUL3nhErEjo//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tai Lopez Slack 4",
          "title": "Tai Lopez Slack 4",
          "src": "/static/a5ec95f110ca602694102e1b5034ac1e/8bffe/tai-lopez-slack-convo-4.jpg",
          "srcSet": ["/static/a5ec95f110ca602694102e1b5034ac1e/a6407/tai-lopez-slack-convo-4.jpg 400w", "/static/a5ec95f110ca602694102e1b5034ac1e/a24e6/tai-lopez-slack-convo-4.jpg 800w", "/static/a5ec95f110ca602694102e1b5034ac1e/8bffe/tai-lopez-slack-convo-4.jpg 1096w"],
          "sizes": "(max-width: 1096px) 100vw, 1096px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1332px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe3c0aD/xAAVEAEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAQABBQIr/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAHRAAAAUFAAAAAAAAAAAAAAAAAAEQITERQWFxgf/aAAgBAQABPyGJW2OpLk4phP/aAAwDAQACAAMAAAAQ48//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFRYaH/2gAIAQEAAT8QsFmppXUL6MPYZIUdMFyPkNT/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tai Lopez Slack 5",
          "title": "Tai Lopez Slack 5",
          "src": "/static/dac718af9e95ffb2d6914e90f575c5b1/7a869/tai-lopez-slack-convo-6.jpg",
          "srcSet": ["/static/dac718af9e95ffb2d6914e90f575c5b1/a6407/tai-lopez-slack-convo-6.jpg 400w", "/static/dac718af9e95ffb2d6914e90f575c5b1/a24e6/tai-lopez-slack-convo-6.jpg 800w", "/static/dac718af9e95ffb2d6914e90f575c5b1/7a869/tai-lopez-slack-convo-6.jpg 1332w"],
          "sizes": "(max-width: 1332px) 100vw, 1332px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1332px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe3c0aD/xAAVEAEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAQABBQIr/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAHRAAAAUFAAAAAAAAAAAAAAAAAAEQITERQWFxgf/aAAgBAQABPyGJW2OpLk4phP/aAAwDAQACAAMAAAAQ48//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFRYaH/2gAIAQEAAT8QsFmppXUL6MPYZIUdMFyPkNT/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tai Lopez Slack 6",
          "title": "Tai Lopez Slack 6",
          "src": "/static/dac718af9e95ffb2d6914e90f575c5b1/7a869/tai-lopez-slack-convo-6.jpg",
          "srcSet": ["/static/dac718af9e95ffb2d6914e90f575c5b1/a6407/tai-lopez-slack-convo-6.jpg 400w", "/static/dac718af9e95ffb2d6914e90f575c5b1/a24e6/tai-lopez-slack-convo-6.jpg 800w", "/static/dac718af9e95ffb2d6914e90f575c5b1/7a869/tai-lopez-slack-convo-6.jpg 1332w"],
          "sizes": "(max-width: 1332px) 100vw, 1332px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1330px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe5U2Gj/xAAWEAEBAQAAAAAAAAAAAAAAAAABECH/2gAIAQEAAQUCMpf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAbEAABBAMAAAAAAAAAAAAAAAAQAAEhQZGx0f/aAAgBAQABPyGjSyGx0//aAAwDAQACAAMAAAAQMA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAwACAwAAAAAAAAAAAAABABEhEDFRYXH/2gAIAQEAAT8QNCvWYl+oPjECGV4bAQ3j/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tai Lopez Slack 7",
          "title": "Tai Lopez Slack 7",
          "src": "/static/504595f0df9083aaf9e083338241cb12/d1a38/tai-lopez-slack-convo-7.jpg",
          "srcSet": ["/static/504595f0df9083aaf9e083338241cb12/a6407/tai-lopez-slack-convo-7.jpg 400w", "/static/504595f0df9083aaf9e083338241cb12/a24e6/tai-lopez-slack-convo-7.jpg 800w", "/static/504595f0df9083aaf9e083338241cb12/d1a38/tai-lopez-slack-convo-7.jpg 1330w"],
          "sizes": "(max-width: 1330px) 100vw, 1330px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Trying this format two days in a row to see what you think!`}</strong></p>
    <PostFooter prevButton={{
      text: postMap.using_big_mario_to_grow_the_aap_audience.label,
      slug: slugify(postMap.using_big_mario_to_grow_the_aap_audience.slug)
    }} nextButton={{
      text: postMap.prototyping_the_third_door.label,
      slug: slugify(postMap.prototyping_the_third_door.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      